import { RenderContentfulRichText } from "../utils/render-contentful-rich-text";
import { createCtaLinkProps } from "../primitives/cta-link.props";
import { createMediaAssetProps } from "../primitives/media-asset.props";
import { createContentfulComponent } from "frontend/contentful/lib/create-contentful-component";
import { DonationCtaEntry } from "frontend/contentful/schema/blocks";
import {
  CtaListRow,
  CtaListRowProps,
} from "design-system/components/blocks/cta-list-row/cta-list-row";
import { useContentful } from "frontend/hooks/use-contentful";

interface DonationCtaOptions {
  headerAbove?: boolean;
}

export const ContentfulDonationCta = createContentfulComponent<
  DonationCtaEntry,
  DonationCtaOptions
>(({ entry, options }) => {
  const { getEntries } = useContentful();
  const headerAbove = options?.headerAbove ?? false;

  const props: CtaListRowProps = {
    title: <RenderContentfulRichText document={entry.fields.title} />,
    description: (
      <RenderContentfulRichText
        document={entry.fields.description}
        disableParagraphs
      />
    ),
    ctasTitle: (
      <RenderContentfulRichText document={entry.fields.donationButtonsTitle} />
    ),
    align: entry.fields.alignment,
    theme: entry.fields.theme,
    HeadingLevel: headerAbove ? "h3" : "h2",
  };

  entry.fields.donationAmountButtons?.forEach((cta) => {
    const ctaLinkProps = createCtaLinkProps(cta);

    if (ctaLinkProps) {
      props.ctas = props.ctas || [];
      props.ctas.push({ ...ctaLinkProps, type: "secondary-button" });
    }
  });

  if (entry.fields.primaryButton) {
    const ctaLinkProps = createCtaLinkProps(entry.fields.primaryButton);

    if (ctaLinkProps) {
      props.ctas = props.ctas || [];
      props.ctas.push({ ...ctaLinkProps, type: "primary-button" });
    }
  }

  getEntries(entry.fields.media)?.forEach((entry) => {
    const asset = createMediaAssetProps(entry);
    if (asset) {
      if (!props.media) props.media = [asset];
      else props.media.push(asset);
    }
  });

  return <CtaListRow {...props} />;
});

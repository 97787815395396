import "./cta-list-row.scss";

import { ReactNode } from "react";
import cn from "clsx";
import {
  CtaLink,
  CtaLinkProps,
} from "design-system/components/primitives/cta-link/cta-link";
import {
  MediaAsset,
  MediaAssetProps,
} from "design-system/components/primitives/media-asset/media-asset";
import { Alignment, MediaAlignment } from "design-system/types/types";
import { Theme } from "design-system/utils/theme";

export interface CtaListRowProps {
  theme?: Theme;
  align?: Exclude<Alignment, "center">;
  title: ReactNode;
  HeadingLevel?: "h2" | "h3";
  description?: ReactNode;
  media?: Array<MediaAssetProps>;
  mediaAlignment?: MediaAlignment;
  ctas?: Array<CtaLinkProps>;
  ctasTitle?: ReactNode;
}

/**
 *
 * ## See it in use on...
 * - The [kitchen sink article page](/story/snowflakes-alumni-example-pages-kitchen-sink--story)
 *
 * - **`data-region: cb__cta-list-row`**
 */
export function CtaListRow({
  align = "full",
  ctas,
  ctasTitle,
  description,
  theme,
  title,
  HeadingLevel = "h2",
  media,
  mediaAlignment = "right",
}: CtaListRowProps) {
  const wrapperClass = cn(`hbs-global-align-${align}`, "hbs-component--cta");

  const CtasTitleHeadingLevel = HeadingLevel === "h3" ? "h4" : "h3";

  return (
    <div className={wrapperClass}>
      <div
        className={cn(
          "hbs-cta-list-row",
          media
            ? `hbs-cta-list-row--asset hbs-cta-list-row--asset-count-${media.length}`
            : `hbs-cta-list-row--text-only`,
        )}
        data-theme={theme}
        data-region="cb__cta-list-row"
      >
        <div
          className={cn(
            "hbs-cta-list-row__wrapper",
            mediaAlignment === "left"
              ? "hbs-cta-list-row__wrapper--media-left"
              : "hbs-cta-list-row__wrapper--media-right",
          )}
        >
          {/* Content */}
          <div className="hbs-cta-list-row__content">
            <div className="hbs-cta-list-row__content-top">
              {title && (
                <HeadingLevel className="hbs-cta-list-row__title">
                  {title}
                </HeadingLevel>
              )}
            </div>
            <div className="hbs-cta-list-row__content-bottom">
              {description && (
                <p className="hbs-cta-list-row__description">{description}</p>
              )}

              {ctas && (
                <div className="hbs-cta-list-row__cta-wrapper">
                  {ctasTitle ? (
                    <CtasTitleHeadingLevel className="hbs-cta-list-row__cta-title">
                      {ctasTitle}
                    </CtasTitleHeadingLevel>
                  ) : null}
                  <div className="hbs-cta-list-row__cta">
                    {ctas.map((cta, index) => (
                      <CtaLink key={index} isSmall={true} {...cta} />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Media */}
          {media && (
            <div className="hbs-cta-list-row__media">
              {media.map((asset, index) => (
                <MediaAsset
                  key={index}
                  {...asset}
                  aspectRatio={
                    media.length === 1 ? "3/2" : index === 1 ? "3/2" : "3/4"
                  }
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
